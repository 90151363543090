import React from 'react';
import phase1Src from '../../assets/img/phase1.svg';
import phase2Src from '../../assets/img/phase2.svg';
import phase3Src from '../../assets/img/phase3.svg';
import RoadMapHeader from './header';

const RoadMapPage = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    var element = document.getElementById('root');
    element.classList.add('wrapper-roadmap');
    if (document.body.classList.contains('mod-2')) {
      document.body.classList.remove('mod-2')
    }
  }, []);

  return (
    <>
      <RoadMapHeader />
      <main className="main">
        <div className="phase">
          <div className="container">
            <div className="phase__bigcontent">
              <div className="phase__card">
                <div className="container">
                  <div className="phase__content">
                    <div className="phase__imagebox">
                      <img src={phase1Src} alt="" className="phase__img" />
                    </div>
                    <h3 className="phase__title">Phase I</h3>
                    <p className="phase__text">
                      Phase I corresponds with the First Migration of the Bunnies to our world, and is meant to lay a
                      stable groundwork for their future activities.
                    </p>
                    <ul className="phase__list">
                      <li className="phase__list-item"> — Gambling and Betting System</li>
                      <li className="phase__list-item"> — Fun and Engaging Games</li>
                      <li className="phase__list-item"> — Citizenship</li>
                      <li className="phase__list-item"> — VS Battle System</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="phase__card">
                <div className="container">
                  <div className="phase__content">
                    <div className="phase__imagebox">
                      <img src={phase2Src} alt="" className="phase__img" />
                    </div>
                    <h3 className="phase__title">Phase II</h3>
                    <p className="phase__text">
                      Phase II will build and heavily expand upon the existing framework, introducing feature like land
                      ownership, and porting some of your favourite game IPs.
                    </p>
                    <ul className="phase__list">
                      <li className="phase__list-item"> — Mobile Companion App</li>
                      <li className="phase__list-item"> — Death and Aging System</li>
                      <li className="phase__list-item"> — Land Ownership</li>
                      <li className="phase__list-item"> — Ports of beloved games IPs</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="phase__card">
                <div className="container">
                  <div className="phase__content">
                    <div className="phase__imagebox">
                      <img src={phase3Src} alt="" className="phase__img" />
                    </div>
                    <h3 className="phase__title">Phase III</h3>
                    <p className="phase__text">
                      Phase III aims to bring our most ambitious ideas yet to life. Social and dating interactions will
                      add yet another layer of depth to our beloved Bunnies.
                    </p>
                    <ul className="phase__list">
                      <li className="phase__list-item"> — Rabbit Breeding</li>
                      <li className="phase__list-item"> — Dating Interactions</li>
                      <li className="phase__list-item"> — Mobile Companion App</li>
                      <li className="phase__list-item"> — VS Battle System</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="communiti">
          <div className="container">
            <div className="communiti__content">
              <h2 className="communiti__title">Community Input</h2>
              <p className="communiti__text">
                We at Moon Rabbit would love to hear about what features YOU would like us to implement moving on. Join
                the community!
              </p>
              <a href="https://discord.io/RadRabbits" target="_blank" className="communiti__button">
                Share your ideas
              </a>
            </div>
          </div>
        </div>
      </main>
      <footer className="footer">
        <div className="container">
          <div className="footer__content">
            <i className="footer__logo icon-head"></i>
            <p className="footer__text">Copyright © 2021. All Rights Reserved.</p>
          </div>
        </div>
      </footer>
    </>
  );
};

export default RoadMapPage;
