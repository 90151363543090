
import { useEffect } from "react"
import { useLocation } from "react-router-dom"
import { Footer } from "./components/Footer"
import { Header } from "./components/Header"
import { MapComponent } from "./components/Maps2"
import "./style.css"

export const Map = () => {

    useEffect(()=>{
        document.body.classList.add('mod-2')

        // if (document.getElementById('root').classList.contains('wrapper')) {
            // document.querySelector('#root').classList.add('wrapper')
        // }
    },[])


    return (
        <>
           <Header />
                <main className="main d-flex align-center " style={{position:'unset'}} >




                   <MapComponent />
                    
                </main>

            <Footer />
        
        </>
    )
}