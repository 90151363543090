import React, { useContext, useEffect } from "react";
import Footer from "../../components/Footer";
import UserContext from "../../utils/contexts/User";
import { AnserwTable } from "./components/AnserwTable";
import { GalleryAside } from "./components/GalleryAside";
import Header from "./components/Header";
import { Data } from "./data/index";
import "./style.css";

export const GalleryPage = () => {
  let { galleryTag, setGalleryTag } = useContext(UserContext);
  // let [result, setResult] = useState([])
  let allData = Data;
  let result = setAnserw(galleryTag.menu);
  // console.log(result)
  

  React.useEffect(() => {
    window.scrollTo(0, 0);
    var element = document.getElementById("root");
    element.classList.add("wrapper-roadmap");
    
    // setResult(setAnserw(galleryTag.menu))
  }, []);
  useEffect(()=>{
    // setResult(setAnserw(galleryTag.menu))
  },[])

  function setAnserw(naming = "ears", obj = allData) {
    if (obj) {
      if (naming == null) {
        if (galleryTag.menuTagArr == null) {
          let arr = [];
          Object.keys(obj).forEach((elem) => {
            arr.push(elem);
          });
          setGalleryTag({ ...galleryTag, menuTagArr: arr });
        }
        if (
          galleryTag.menuTagArr !== null &&
          (galleryTag.menuTagArr.length < Object.keys(obj).length ||
            galleryTag.menuTagArr.length > Object.keys(obj).length)
        ) {
          let arr = [];
          Object.keys(obj).forEach((elem) => {
            arr.push(elem);
          });
          setGalleryTag({ ...galleryTag, menuTagArr: arr });
        }
        return obj[Object.keys(obj)[0]];
      }

      return obj[naming];
    }
  }

  return (
    <>
      <Header />
      <main className="main">
        <div className="main_gallery container">
          <GalleryAside
            array={galleryTag.menuTagArr ? galleryTag.menuTagArr : null}
          />
          {result.length > 0 ? <AnserwTable array={result} /> : <></>}
          
        </div>
      </main>
      <Footer />
    </>
  );
};
