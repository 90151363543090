import React, { useEffect } from "react";
import { BrowserRouter, Route, Switch, useLocation } from "react-router-dom";
import { GalleryPage } from "./pages/Gallery";
import HomePage from "./pages/Home";
import { Map } from "./pages/Map";
import NoMatch from "./pages/NoMatch/index";
import RoadMapPage from "./pages/RoadMap/index";
import SubPage from "./pages/Subscribe";
import * as RoutePaths from "./utils/constants/routings";

function Routings() {


  return (
    <>
      <BrowserRouter>
        <Switch>
          {/* <HomeLayout> */}
          <Route exact path={RoutePaths.HOME} component={HomePage} />
          <Route exact path={RoutePaths.ROADMAP} component={RoadMapPage} />
          <Route exact path={RoutePaths.GALLERY} component={GalleryPage} />
          <Route exact path={RoutePaths.SUB} component={SubPage} />
          <Route exact path={RoutePaths.MAP} component={Map} />
          <Route path="*" component={HomePage} />
          {/* <Route component={NoMatch} /> </HomeLayout> */}
        </Switch>
      </BrowserRouter>
    </>
  );
}

export default Routings;
