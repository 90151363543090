import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Web3 from 'web3';
import rarity from '../../../../assets/img/Rad-rabbit-rarity.png';
import soldout from '../../../../assets/img/soldout.png';
import { moonRabbitContractAddress } from '../../../../utils/constants/variables';
import UserContext from '../../../../utils/contexts/User';
import { getContractAbi } from '../../../../utils/web3Utils';

const dateEnd = moment('2021-12-16 20:00').toDate();

const BuyBunny = () => {
  const data = React.useContext(UserContext);
  const [totalSupply, setTotalSupply] = useState(0);
  const [price, setPrice] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isPaid, setPaid] = useState(false);
  const [count, setCount] = useState();

  const handleBuyBunny = async (e) => {
    e.preventDefault();
    setPaid(false);
    let form = document.forms[0];
    let count = form.elements.count.value;

    const web3 = window.ethereum;

    if (web3) {
      const web3 = new Web3(window.ethereum);
      setLoading(true);
      setCount(count);
      await data.contract.methods
        .mint(count)
        .send({ from: data.account.address, value: web3.utils.toWei((count * price).toString()) })
        .on('receipt', function (receipt) {
          getData();
          setLoading(false);
          setPaid(true);
          form.elements.count.value = '';
        })
        .on('error', (error) => setLoading(false));
    }
  };

  const getDataFromMetamask = async () => {
    const web3 = window.ethereum;

    if (web3) {
      const web3 = new Web3(window.ethereum);
      // const saleStarted = await data.contract.methods
      //   .saleStarted()
      //   .call()
      //   .then((result) => {
      //     console.log('saleStarted', result);
      //   })
      //   .catch((e: any) => console.error(e));

      await data.contract.methods
        .totalSupply()
        .call()
        .then((result) => {
          setTotalSupply(result);
        })
        .catch((e: any) => console.error(e));

      await data.contract.methods
        .getPrice()
        .call()
        .then((result) => {
          setPrice(web3.utils.fromWei(result));
        })
        .catch((e: any) => console.error(e));
    }
  };

  const getData = async () => {
    const web3 = new Web3('https://evm.moonrabbit.com');
    // console.log('web3', web3);
    const contract = new web3.eth.Contract(getContractAbi().moonRabbitJson.abi, moonRabbitContractAddress);
    // console.log('contract', contract);
    if (contract) {
      await contract.methods
        .totalSupply()
        .call()
        .then((result) => {
          setTotalSupply(result);
        })
        .catch((e: any) => console.error(e));

      await contract.methods
        .getPrice()
        .call()
        .then((result) => {
          setPrice(web3.utils.fromWei(result));
        })
        .catch((e: any) => console.error(e));
    }
  };

  useEffect(() => {
    if (data.contract && window.ethereum) {
      getDataFromMetamask();
    } else {
      getData();
    }
  }, [data.contract, window.ethereum]);

  return (
    <div className="buybunny">
      <div className="container">
        <div className="buybunny__content">
          <div className="buybunny__block">
            <img src={soldout} alt="" className="trait__img" />
          </div>
          <div className="buybunny__block">
            
            <h1 className="buybunny__title">Sold 7181 of 8888</h1>

            <div className="buybunny__text">
              <>
                
              </>
               <p>
                Thank you for your interest in our exclusive collection.
              </p>
              <p style={{ fontWeight: 'bold' }}>Rad Rabbits Reveal happened on December 28, 2021.</p>
              
              <p>Thank you for participating.</p>
              <br />
              <p>Join our Telegram to learn more!</p>
              <br />
              <a href="https://t.me/moonrabbitcom" className="customButton " rel="noreferrer" target="_blank">
                Join Telegram
              </a>

            </div>
          </div>
        </div>
      </div>
      <div className="rarity">
        <div className="container">
          <div className="buybunny__rarity">
            <img src={rarity} alt="" className="trait__img" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BuyBunny;
