import React from 'react';

const Footer = () => {
  return (
    <footer className="footer">
            <div className="container">
                <div className="footer__content">
                    <i className="footer__logo icon-head"></i>
                    <p className="footer__text">Copyright © 2021. All Rights Reserved.</p>
                </div>
            </div>
        </footer>
  );
};

export default Footer;