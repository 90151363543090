import React from "react";
import { useHistory } from "react-router";
import * as RoutePaths from "../../../../utils/constants/routings";


const Header = () => {
  const history = useHistory();
  // --------------------------
  // header taken from RoadMap!!!!
  // --------------------------
  return (
    <header className="header header-roadmap">
      <nav className="navigation">
        <div className="container">
          <div className="navigation__content">
            <div className="navigation__column">
              <div
                className="logo icon-head"
                onClick={() => history.push(RoutePaths.HOME)}
              ></div>
              <div
                className="_pc navigation__link"
                onClick={() => history.push(`${RoutePaths.HOME}#buy`)}
              >
                Buy Rad Rabbit
              </div>
              <div
                className="roadmap__link navigation__link"
                onClick={() => history.push(RoutePaths.ROADMAP)}
              >
                {" "}
                Roadmap
              </div>

              <div
                className="roadmap__link navigation__link"
                onClick={() => history.push(RoutePaths.GALLERY)}
              >
                Gallery
              </div>
              {/* <a
                className="roadmap__landinglink navigation__link"
                href="http://radmap.org" target="blank"
              >
                <img src={mapbtn} height="50px" alt="" />
              </a> */}
            </div>
            <div className="navigation__column social__column">
              <a
                href="https://discord.io/RadRabbits"
                className="social icon-discord"
                target="_blank"
              ></a>
              <a
                href="https://twitter.com/moonrabbitaz"
                className="social icon-twitter"
                target="_blank"
              ></a>
              <a
                href="https://t.me/moonrabbitcom"
                className="social icon-telegram"
                target="_blank"
              ></a>
              {/* <div href="#" className="social menu__burger _mob">
                  <span></span>
                  <span></span>
                </div> */}
              {/* <a href="#" className="social navigation__link _pc">
                  Blog
                </a> */}
            </div>
          </div>
        </div>
      </nav>
      <div className="roadmap">
        <div className="container">
          <div className="roadmap__content">
            <h2 className="roadmap__title">Rarity</h2>
            <p className="roadmap__text">
              Check all the Rad Rabbit features and get a perspective on how
              rare your items are!
            </p>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
