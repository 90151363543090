import React from "react";
import "./style.css";

export const AnserwItem = (props) => {
  const { imgUri, name, chance, index } = props;

  return (
    <div className="item" key={index}>
      <div className="item__img">
        <div className="item__img-wrapper">
          <img src={imgUri} alt="" />
          {/* <span className="item__img__span">
            <svg viewBox="0 0 18 18">
              <path
                d="M7 3V5H2V16H13V11H15V17C15 17.2652 14.8946 17.5196 14.7071 17.7071C14.5196 17.8946 14.2652 18 14 18H1C0.734784 18 0.48043 17.8946 0.292893 17.7071C0.105357 17.5196 0 17.2652 0 17V4C0 3.73478 0.105357 3.48043 0.292893 3.29289C0.48043 3.10536 0.734784 3 1 3H7ZM18 0V9L14.206 5.207L8.207 11.207L6.793 9.793L12.792 3.793L9 0H18Z"
                fill="white"
              />
            </svg>
          </span> */}
        </div>
      </div>
      <div className="discription">
        <div className="discription__title">{name}</div>
        <div className="discription__chance">{chance}</div>
      </div>
    </div>
  );
};
