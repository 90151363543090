import React, { useContext } from "react";
import UserContext from "../../../../utils/contexts/User";
import { AsideList } from "../AsideList";
import "./style.css";

export const GalleryAside = (props) => {
  let { galleryTag } = useContext(UserContext);
  // console.log(galleryTag.menuTagArr);
  let handleChange = (event) => {};

  let handleClickUp = (event) => {};

  return (
    <>
      <aside className="aside">
        {/* <div className="sorting">
          <div className="sorting__select-wrapper">
            <select
              className="sorting__select"
              name="sort"
              id="sort"
              onChange={handleChange}
            >
              <option value="" defaultValue hidden>
                Sort by Price
              </option>
              <option value="">sort item</option>
            </select>
            <span className="sorting__select-arrow">
              <svg
                width="12"
                height="8"
                viewBox="0 0 12 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.00011 4.97668L10.1251 0.851685L11.3034 2.03002L6.00011 7.33335L0.696777 2.03002L1.87511 0.851685L6.00011 4.97668Z"
                  fill="black"
                />
              </svg>
            </span>
          </div>

          <span className="sorting__button disabled">
            <svg
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.58374 2.871V12H5.08374V2.871L1.06074 6.894L0.000244141 5.8335L5.83374 0L11.6672 5.8335L10.6067 6.894L6.58374 2.871Z"
                // fill="white"
              />
            </svg>
          </span>
          <span className="sorting__button down active">
            <svg
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M6.58374 2.871V12H5.08374V2.871L1.06074 6.894L0.000244141 5.8335L5.83374 0L11.6672 5.8335L10.6067 6.894L6.58374 2.871Z" />
            </svg>
          </span>
        </div> */}
        <div className="aside__list-wrapper">
          <AsideList menu={galleryTag.menuTagArr} />
        </div>
      </aside>
    </>
  );
};
