import React from "react";
import { AnserwItem } from "../AnserwItem";
import "./style.css";

export const AnserwTable = (props) => {
  const { array } = props;

  const getAnserw = (a) => {
    let anserw = [];
    if (a.length > 0) {
      for (let i = 0; i < a.length; i++) {
        anserw.push(setAnserw(a[i], i));
      }
    }
    return anserw;
  };

  function setAnserw(obj, index) {
    let err;
    let checkedObj = {};
    if (obj) {
      if (obj.img_uri) {
        checkedObj.uri = obj.img_uri;
      } else {
        // set "NO IMG" || "IMG COMMING SOON" from assets if you need
        checkedObj.uri = null;
        // err = true;
      }
      if (obj.itemName) {
        checkedObj.name = obj.itemName;
      } else {
        // err = true;
      }
      if (obj.rarity) {
        let rarity = (obj.rarity * 100).toFixed(2);
        checkedObj.chance = rarity + "%";
      } else {
        // err = true;
      }
    }
    if (!err) {
      if (Object.keys(checkedObj).length !== 0) {
        // Here you can also add "if" to check obj
        return (
          <AnserwItem
            imgUri={checkedObj.uri}
            name={checkedObj.name}
            chance={checkedObj.chance}
            key={`${index}`}
          />
        );
      }
    }
  }

  return <div className="table">{getAnserw(array)}</div>;
};
