/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useHistory, useLocation } from "react-router";
import logoSrc from "../../../../assets/img/moonrabbit-logo.png";
import * as RoutePaths from "../../../../utils/constants/routings";

import mapbtn from '../../../../assets/img/btns/1.png';


const Header = () => {
  const location = useLocation();
  const history = useHistory();

  return (
    <header className="header">
      <nav className="navigation">
        <div className="container">
          <div className="navigation__content">
            <div className="navigation__column">
              <div
                className="logo icon-head"
                onClick={() => history.push(RoutePaths.HOME)}
              ></div>

              <a href="#buy" className="_pc navigation__link">
                Buy Rad Rabbit
              </a>
              {/* 
              <a href={RoutePaths.ROADMAP} className="_pc navigation__link">
                Roadmap
              </a> */}

              <div
                className="roadmap__landinglink navigation__link"
                onClick={() => history.push(RoutePaths.ROADMAP)}
              >
                Roadmap
              </div>
              <div
                className="roadmap__landinglink navigation__link"
                onClick={() => history.push(RoutePaths.GALLERY)}
              >
                Gallery
              </div>

              {/* <a
                className="roadmap__landinglink navigation__link"
                href="http://radmap.org" target="blank"
              >
                <img src={mapbtn} height="50px" alt="" />
              </a> */}
              {/* <a href="#" className="_pc navigation__link">
                Gallery
              </a> */}
            </div>
            <div className="navigation__column social__column">
              <a
                href="https://discord.io/RadRabbits"
                className="social icon-discord"
                target="blank"
              ></a>
              <a
                href="https://twitter.com/moonrabbitaz"
                className="social icon-twitter"
                target="blank"
              ></a>
              <a
                href="https://t.me/moonrabbitcom"
                className="social icon-telegram"
                target="blank"
              ></a>
              {/* <a href="#" className="social navigation__link _pc">
                Blog
              </a> */}
            </div>
          </div>
        </div>
      </nav>

      <div className="container">
        <div className="header__content">
          <div className="header__content">
            <h1 className="header__title">
              It’s <br className="_mob" />
              time <br className="_mob" />
              to get <br className="_mob" />
              RADical! <br className="_mob" />
            </h1>
            <p className="header__text _mob ">
                <a
                  className="roadmap__landinglink"
                  style={{display:'inline-block',cursor:'pointer'}} 
                  onClick={() => history.push(RoutePaths.MAP)}
                >
                  <img src={mapbtn} height="80px" alt=""  />
                </a>

            </p>
            <p className="header__text">
              Rad Rabbits have descended on Earth and are starting to organize
              into clans. Join their efforts to create a just & fair bunny
              society and defend against the ultimate enemy -{" "}
              <b className="text_bold">time itself.</b>
            </p>
            <p className="header__text _pc" style={{width: '45%'}}>
                <a
                  className="roadmap__landing link"
                  target="blank" style={{display:'inline-block',cursor:'pointer'}} 
                  onClick={() => history.push(RoutePaths.MAP)}
                >
                  <img src={mapbtn} height="120px" alt=""  />
                </a>

            </p>
            
            <span className="header__line"></span>
            <p className="header__text text_bold header__bold">
              8888 AI-Generated NFT Avatars on Moon Rabbit Metachain
            </p>
            <img src={logoSrc} alt="" className="header__moonrabbit" />
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
