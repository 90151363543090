import React from 'react';
import embryo from '../../../../assets/img/bunny-embryo.png';

const Bunniverse = () => {

  return (
    <div className="bunniverse">
      <div className="container">
        <div className="bunniverse__content">
          <div className="bunniverse__block">
            <h2 className="bunniverse__title">Get Lost in the Bunnyverse</h2>
            <p className="bunniverse__text">
              The First Bunny Kingdom on Earth is a vast, exciting world still not yet fully explored.
              <br />
              <br />
              Your Rad Rabbits will mature, eventually age and look up to you to deliver them from death. They will
              fight against others on the blockchain when they feel threatened, or might resolve to other competitive
              activities, like racing, to settle their differences. They might buy land, farm carrots, and then gamble
              it all away in a single stroke of bad luck. Some of them perhaps will even fall in love.
              <br />
              <br />
              The possibilities in the Bunnyverse are endless, as are the appetites of its inhabitants. Join us now and
              let’s start exploring them together.
            </p>
          </div>
          <div className="bunniverse__block">
          <img src={embryo} alt="" className="trait__img" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Bunniverse;
