import React from 'react';
import { useLocation } from 'react-router';
import '../../../src/assets/css/icomoon.css';
import '../../../src/assets/css/style.css';
import Footer from '../../components/Footer/index';
import Bunniverse from './components/Bunniverse/index';
import BunnyMap from './components/BunnyMap';
import BuyBunny from './components/BuyBunny';
import Header from './components/Header';
import HeroesSlider from './components/Heroes/index';
import Story from './components/Story';
import Trait from './components/Trait';

const HomePage = () => {
  const { pathname, hash, key } = useLocation();

  React.useEffect(() => {
    var element = document.getElementById('root');
    if (element.classList.contains('wrapper-roadmap')) {
      element.classList.remove('wrapper-roadmap');
    }
    if (document.body.classList.contains('mod-2')) {
      document.body.classList.remove('mod-2')
  }
  }, []);

  
  React.useEffect(() => {
    if (hash === '') {
      window.scrollTo(0, 0);
    } else {
      const anchors = document.querySelectorAll('a[href*="#"]');
      for (let anchor of anchors) {
        anchor.addEventListener('click', function (e) {
          e.preventDefault();
          const blockID = anchor.getAttribute('href').substr(1);
          document.getElementById(blockID).scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
        });
      }
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 0);
    }
  }, [pathname, hash, key]);

  return (
    <>
      <Header />
      <main className="main">
        <HeroesSlider />
        <Trait />
        <BuyBunny />
        <Story />
        <Bunniverse />
        <BunnyMap />
      </main>

      <Footer />
    </>
  );
};

export default HomePage;
