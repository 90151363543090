import React, { useEffect, useState } from 'react';
import '../src/assets/css/style.css';
import Routings from './routing';
import { moonRabbitChainId } from './utils/constants/variables';
import UserContext from './utils/contexts/User';
import { createContract, getActiveAccount, getEthBalance, initWeb3 } from './utils/web3Utils';

function App() {
  const [loading, setLoading] = useState(true);
  const [contract, setContract] = useState(null);
  const [web3, setWeb3] = useState(null);
  const [connectNetwork, setConnectNetwork] = useState(true);
  const [account, setAccount] = useState({
    address: null,
    ethBalance: 0,
    updateUserData: null,
  });
  
  let [galleryTag, setGalleryTag] = useState({
    menu: null,
    menuTagArr: null,
  });


  const getData = React.useCallback(async () => {
    const contract = await createContract();
    setContract(contract);
    if (contract) {
      const address = await getActiveAccount();
      if (address) {
        const ethBalance = await getEthBalance(address);
        setAccount({
          address,
          ethBalance,
          updateUserData: async () => getData(),
        });
      }
    }
  }, []);

  const listenMMAccount = React.useCallback(async () => {
    if (window.ethereum) {
      window.ethereum.on('accountsChanged', async () => getData());
      window.ethereum.on('chainChanged', async () => checkNetwork());
    }
  }, [getData]);

  const checkNetwork = () => {
    setConnectNetwork(window.ethereum.chainId === moonRabbitChainId);
  };

  useEffect(() => {
    setLoading(true);
    initWeb3().then((web3) => {
      if (web3) {
        setWeb3(web3);
        const connectToNetwork = window.ethereum.chainId == moonRabbitChainId;
        setConnectNetwork(connectToNetwork);
        if (connectToNetwork) {
          getData();
          listenMMAccount();
        }
      }
    });

    setLoading(false);
  }, []);

  return (
    <>
      {!loading && (
        <UserContext.Provider value={{ account, contract, connectNetwork, loading, web3,galleryTag, setGalleryTag }}>
          <Routings />
        </UserContext.Provider>
      )}
    </>
  );
}

export default App;
