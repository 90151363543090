import img1 from '../../assets/img/rabbit.png'
import logoMin from '../../assets/svg/footer-logo.svg'


export const Footer = () =>{
    return (
        <>
            <div className="footer d-flex justify-content-between">
                <div className="footer-col">
                    <div className="footer-add-img">
                        <img className="footer-img" src={img1} alt="footer-img" />
                    </div>
                    
                </div>
                <div className="footer-col">
                    <div className="footer-content-bg d-flex align-center col-gap-3 mb-2 mr-6">
                        <p className="footer-content-paragraph">
                            Powered by Moon Rabbit 
                           
                        </p> 
                        <img src={logoMin} alt="" />
                    </div>
                </div>
            </div>
        </>
    )
}