import React from 'react';
import bunniesMobSrc from '../../../../assets/img/bunnies-mob.png';
// import bunniesSrc from '../../../../assets/img/bunnies-pc.png';
import { ReactComponent as BunniesImg } from '../../../../assets/img/bunnies.svg';

const Trait = () => {
  return (
    <div className="trait">
      <div className="container">
        <div className="trait__content">
          <div className="trait__block _pc">
            <BunniesImg className="trait__img" alt="" />
            {/* <img src={bunniesSrc} alt="" className="trait__img" /> */}
          </div>
          <div className="trait__block">
            <h2 className="trait__title">Trait Rarity</h2>
            <p className="trait__text trait__text_1">
              Each Rad Rabbit has seven distinctive traits that differentiate it from all the others and allow for tens
              of thousands of unique combinations.
            </p>
            <img src={bunniesMobSrc} alt="" className="trait__img _mob" />
            <p className="trait__text trait__text_2">
              Some Rad Rabbits even go as far as to impersonate characters you just might recognize. Those legendary
              versions are very rare indeed and many DAOs are hunting them, offering fat bounties of $AAA to catch one!
            </p>
          </div>
        </div>
      </div>
      <div id="buy"></div>
    </div>
  );
};

export default Trait;
