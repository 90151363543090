import { useEffect, useState } from 'react'


export const ModalContentV2 = (props) => {
    const [state, setState] = useState(false) 

    const {modalImgPath} = props.info
    const {callBack} = props

    const loading = (e) =>{

        setState(true)
        callBack(false)
        // if (state) {
            
        // }
        
    }

    return (
        <>
            <div className="d-flex align-center justify-content-center px-2 ">
                
                {
                    !state ? (
                        <svg className='modal-loading position-absolute' width="250px" height="250px" version="1.1" id="L5" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                            viewBox="0 0 60 100" enableBackground="new 0 0 0 0" xmlSpace="preserve">
                            <circle fill="#fff" stroke="none" cx="6" cy="50" r="6">
                            <animateTransform 
                                attributeName="transform" 
                                dur="1s" 
                                type="translate" 
                                values="0 15 ; 0 -15; 0 15" 
                                repeatCount="indefinite" 
                                begin="0.1"/>
                            </circle>
                            <circle fill="#fff" stroke="none" cx="30" cy="50" r="6">
                            <animateTransform 
                                attributeName="transform" 
                                dur="1s" 
                                type="translate" 
                                values="0 10 ; 0 -10; 0 10" 
                                repeatCount="indefinite" 
                                begin="0.2"/>
                            </circle>
                            <circle fill="#fff" stroke="none" cx="54" cy="50" r="6">
                            <animateTransform 
                                attributeName="transform" 
                                dur="1s" 
                                type="translate" 
                                values="0 5 ; 0 -5; 0 5" 
                                repeatCount="indefinite" 
                                begin="0.3"/>
                            </circle>
                        </svg>
                    ):<></>
                }
                <div className={state ? `modal-var-2`: ''} style={state ? {opacity : 1}: {opacity:0}} >
                    <img className="modal-img" src={modalImgPath} alt="modal-item-img" onLoad={loading}  />
                </div>
               
            </div>
            
            {/* <div  className=' vh-100 modal container-min text-center with-out-before py-0 px-0 '>
                <div className="modal-img-wrap h-100 ">
                    <img src={imgTest} alt="modal-item-img" />
                </div>

            </div> */}
        </>
    )
}