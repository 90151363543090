
export const ModalContentV1 = () => {
    return (
        <>

            <div className=" modal container-min text-center">
                <div className="modal-inner">

                    <p>
                        The vote privilege is reserved for collection holders. We didn’t detect
                    <br />
                        a Rad Rabbit NFT in your wallet.
                    </p>
                    <p>
                        Visit the Marketplace and get one to participate in this vote
                        <br />
                        and all the others to come.
                    </p>
                    <small>
                        Click anywhere to disband this message.
                    </small>

                </div>
              
                
            </div>
        </>
    )
}