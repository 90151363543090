import React from 'react';
import bunnymap from '../../../../assets/img/bunny-map.png';

const BunnyMap = () => {
  return (
    
    <div className="container">
        <div className=" .map__img">
          <img src={bunnymap} alt="" className="map__img" />
        </div>
    </div>
  );
};

export default BunnyMap;
