import { Link, useHistory, useLocation } from 'react-router-dom'
import logo from '../../assets/svg/logo-name.svg'
import * as RoutePaths from "../../../../utils/constants/routings";

export const Header = () =>{

    const location = useLocation();
    const history = useHistory();
    const handleSandwichClick =() =>{
        document.querySelector('.header-nav-list').classList.toggle('active')
    }

    return(
        <header className="header-mod-2 d-flex my-5 mx-6 align-center justify-content-between">
            <div className="logo">
                <a  onClick={() => history.push(RoutePaths.HOME)}>
                    <img src={logo} alt="logo" />
                </a>
                
            </div>
            <nav className="header-nav py-3">
                <ul className="header-nav-list d-flex justify-content-end col-gap-2">
                    <li className="li-none-list-style --desktop">
                        <a className="btn" target="blank" href="https://www.radrabbits.org/gallery">
                            Check Rad Gallery
                        </a>
                    </li>
                    <li className="li-none-list-style --desktop">
                        <a className="btn" target="blank" href="https://www.nft.moonrabbit.com/collection/0x073A3d7E2D77a2a3f9bd7D0535971e7951a1a22b">
                            Visit Marketplace
                        </a>
                    </li>
                   
                </ul>
                <ul className="m-0">
                    <li className="li-none-list-style cursor-pointer --mobile pr-1" onClick={handleSandwichClick}>
                        <svg viewBox="0 0 80 45" width="40" height="100%" fill="#ffffff">
                            <rect width="80" height="5"></rect>
                            <rect y="20" width="80" height="5"></rect>
                            <rect y="40" width="80" height="5"></rect>
                        </svg>
                    </li>
                </ul>
                
            </nav>
        </header>
   
)}