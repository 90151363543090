import React from "react";
import Footer from "../../components/Footer";
import Header from "./Components/Header/";
import "./style.css";

const SubPage = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    var element = document.getElementById("root");
    element.classList.add("wrapper-roadmap");
  }, []);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    return false;
  };

  return (
    <>
      <Header />
      <main className="subscribe-page main">
        <section className="subscribe">
          <div className="subscribe__top">
            <h1 className="subscribe__title --white">
              Inquisitive minds get rewarded
            </h1>
            <p class="subscribe__paragraph --white">
              Enter your email to receive a free Rad Rabbit minting
            </p>
          </div>
          <div className="submit__form-wrapper">
            <form onsubmit={handleFormSubmit}>
              <div className="submit__form-inner">
                <input
                  className="subscribe__submit-input --default --clickable"
                  type="text"
                  name="email"
                  autoComplete="off"
                  placeholder="Enter your email"
                  id=""
                />
                <input
                  className="subscribe__submit-btn --default --clickable"
                  type="submit"
                  value="Submit"
                />
              </div>
            </form>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
};

export default SubPage;
