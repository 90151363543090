import React from 'react';

const Story = () => {
  return (
    <div className="story">
      <div className="container">
        <div className="story__content">
          <i className="story__icon icon-head"></i>
          <p className="story__text story__text_1 text_italy">
            En masse, the bunnies descended upon the planet their progenitor had come from, each shedding their pristine
            shell in the process, not as much as in defiance of their creator - but for the opportunity to for the first
            time take a form of their own choosing.
          </p>
          <span className="story__line"></span>
          <p className="story__text story__text_2">
            From the “{ <a href="https://drive.google.com/file/d/1yP0Tg1n3W_fh2owqPvBnWH_9D85JkFwg/view?usp=sharing" target="_blank" className="story__link">Book of the First Bunny</a> }”.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Story;
