export const Data = {
  Legendaries: [
    {
      index: "LEGENDARIES 03",
      itemName: "SAW BILLY",
      amount: null,
      rarity: null,
      img_uri: "img/characters/BILLY.png",
    },
    {
      index: "LEGENDARIES 04",
      itemName: "BOGDANOFF",
      amount: null,
      rarity: null,
      img_uri: "img/characters/BOGDANOFF.png",
    },
    {
      index: "LEGENDARIES 05",
      itemName: "BUSTA RHYMES",
      amount: null,
      rarity: null,
      img_uri: "img/characters/BUSTA RHYMES.png",
    },
    {
      index: "LEGENDARIES 06",
      itemName: "CZ",
      amount: null,
      rarity: null,
      img_uri: "img/characters/CZ.png",
    },
    {
      index: "LEGENDARIES 07",
      itemName: "DARTH VADER",
      amount: null,
      rarity: null,
      img_uri: "img/characters/DARTH VADER.png",
    },
    {
      index: "LEGENDARIES 08",
      itemName: "DARYL DIXON",
      amount: null,
      rarity: null,
      img_uri: "img/characters/DARYL DIXON.png",
    },
    {
      index: "LEGENDARIES 09",
      itemName: "ELON MUSK / BORING",
      amount: null,
      rarity: null,
      img_uri: "img/characters/ELON MUSK BORING.png",
    },
    {
      index: "LEGENDARIES 10",
      itemName: "ELON MUSK / ECCENTRIC",
      amount: null,
      rarity: null,
      img_uri: "img/characters/ELON MUSK ECCENTRIC.png",
    },
    {
      index: "LEGENDARIES 11",
      itemName: "ELON MUSK / MARS",
      amount: null,
      rarity: null,
      img_uri: "img/characters/ELON MUSK MARS.png",
    },
    {
      index: "LEGENDARIES 12",
      itemName: "ELON MUSK / TESLA",
      amount: null,
      rarity: null,
      img_uri: "img/characters/ELON MUSK TESLA.png",
    },
    {
      index: "LEGENDARIES 13",
      itemName: "ELON MUSK / DOGE",
      amount: null,
      rarity: null,
      img_uri: "img/characters/ELONMUSK DOGE.png",
    },
    {
      index: "LEGENDARIES 14",
      itemName: "ELON MUSK / T.I.T.S.",
      amount: null,
      rarity: null,
      img_uri: "img/characters/ELONMUSK TITS.png",
    },
    {
      index: "LEGENDARIES 15",
      itemName: "RABIOSA / BUNYOSA",
      amount: null,
      rarity: null,
      img_uri: "img/characters/FURIOSA.png",
    },
    {
      index: "LEGENDARIES 01",
      itemName: "ABELLA DANGER",
      amount: null,
      rarity: null,
      img_uri: "img/characters/ABELLADANGER.png",
    },
    {
      index: "LEGENDARIES 16",
      itemName: "GHOST",
      amount: null,
      rarity: null,
      img_uri: "img/characters/GHOST.png",
    },
    {
      index: "LEGENDARIES 17",
      itemName: "GORDON FREEMAN",
      amount: null,
      rarity: null,
      img_uri: "img/characters/GORDON FREEMAN.png",
    },
    {
      index: "LEGENDARIES 18",
      itemName: "HEISENBERG",
      amount: null,
      rarity: null,
      img_uri: "img/characters/HEISENBERG.png",
    },
    {
      index: "LEGENDARIES 19",
      itemName: "ISLANDBOY FLYYSOULJAH",
      amount: null,
      rarity: null,
      img_uri: "img/characters/ISLANDBOY FLYYSOULJAH.png",
    },
    {
      index: "LEGENDARIES 20",
      itemName: "ISLANDBOY KODIYAKREDD",
      amount: null,
      rarity: null,
      img_uri: "img/characters/ISLANDBOY KODIYAKREDD.png",
    },
    {
      index: "LEGENDARIES 21",
      itemName: "JASON VOORHEES",
      amount: null,
      rarity: null,
      img_uri: "img/characters/JASON VOORHEES.png",
    },
    {
      index: "LEGENDARIES 22",
      itemName: "JOHN DOE",
      amount: null,
      rarity: null,
      img_uri: "img/characters/JOHN DOE.png",
    },
    {
      index: "LEGENDARIES 23",
      itemName: "JOHN MCAFEE",
      amount: null,
      rarity: null,
      img_uri: "img/characters/JOHN MCAFEE.png",
    },
    {
      index: "LEGENDARIES 24",
      itemName: "JOKER",
      amount: null,
      rarity: null,
      img_uri: "img/characters/JOKER.png",
    },
    {
      index: "LEGENDARIES 25",
      itemName: "GOD OF WAR",
      amount: null,
      rarity: null,
      img_uri: "img/characters/KRATOS.png",
    },
    {
      index: "LEGENDARIES 26",
      itemName: "SHIB",
      amount: null,
      rarity: null,
      img_uri: "img/characters/LASER BUNNY.png",
    },
    {
      index: "LEGENDARIES 27",
      itemName: "MESSIAH",
      amount: null,
      rarity: null,
      img_uri: "img/characters/MESSIAH.png",
    },
    {
      index: "LEGENDARIES 28",
      itemName: "DIA DE LOS MUERTOS",
      amount: null,
      rarity: null,
      img_uri: "img/characters/MEXICAN DEAD.png",
    },
    {
      index: "LEGENDARIES 29",
      itemName: "MIKE TYSON",
      amount: null,
      rarity: null,
      img_uri: "img/characters/MIKE TYSON.png",
    },
    {
      index: "LEGENDARIES 30",
      itemName: "MONA LISA",
      amount: null,
      rarity: null,
      img_uri: "img/characters/MONA LISA.png",
    },
    {
      index: "LEGENDARIES 31",
      itemName: "MORTY",
      amount: null,
      rarity: null,
      img_uri: "img/characters/MORTY.png",
    },
    {
      index: "LEGENDARIES 32",
      itemName: "MR ROBOT",
      amount: null,
      rarity: null,
      img_uri: "img/characters/MR ROBOT.png",
    },
    {
      index: "LEGENDARIES 33",
      itemName: "MR YUNIOSHI",
      amount: null,
      rarity: null,
      img_uri: "img/characters/MR YUNIOSHI.png",
    },
    {
      index: "LEGENDARIES 34",
      itemName: "NATIONAL TREASURE",
      amount: null,
      rarity: null,
      img_uri: "img/characters/NICOLAS CAGE.png",
    },
    {
      index: "LEGENDARIES 35",
      itemName: "QUEEN ELIZABETH",
      amount: null,
      rarity: null,
      img_uri: "img/characters/QUEEN ELIZABETH.png",
    },
    {
      index: "LEGENDARIES 36",
      itemName: "RICK",
      amount: null,
      rarity: null,
      img_uri: "img/characters/RICK.png",
    },
    {
      index: "LEGENDARIES 37",
      itemName: "SALVADOR DALI",
      amount: null,
      rarity: null,
      img_uri: "img/characters/SALVADOR DALI.png",
    },
    {
      index: "LEGENDARIES 38",
      itemName: "SAM",
      amount: null,
      rarity: null,
      img_uri: "img/characters/SAM.png",
    },
    {
      index: "LEGENDARIES 39",
      itemName: "SILVER SAMURAI",
      amount: null,
      rarity: null,
      img_uri: "img/characters/SILVER SAMURAI.png",
    },
    {
      index: "LEGENDARIES 40",
      itemName: "SNOOP DOGG",
      amount: null,
      rarity: null,
      img_uri: "img/characters/SNOOP DOGG.png",
    },
    {
      index: "LEGENDARIES 41",
      itemName: "SOLAIRE",
      amount: null,
      rarity: null,
      img_uri: "img/characters/SOLAIRE.png",
    },
    {
      index: "LEGENDARIES 42",
      itemName: "SOULJA BOY",
      amount: null,
      rarity: null,
      img_uri: "img/characters/SOULJA BOY.png",
    },
    {
      index: "LEGENDARIES 43",
      itemName: "SQUID STAFF",
      amount: null,
      rarity: null,
      img_uri: "img/characters/SQUID STAFF.png",
    },
    {
      index: "LEGENDARIES 44",
      itemName: "THOMAS SHELBY",
      amount: null,
      rarity: null,
      img_uri: "img/characters/THOMAS SHELBY.png",
    },
    {
      index: "LEGENDARIES 45",
      itemName: "VITALIK BUTERIN",
      amount: null,
      rarity: null,
      img_uri: "img/characters/VITALIK BUTERIN.png",
    },
    {
      index: "LEGENDARIES 02",
      itemName: "JOJO RABBIT",
      amount: null,
      rarity: null,
      img_uri: "img/characters/ADOLF HITLER.png",
    },
    {
      index: "LEGENDARIES 46",
      itemName: "WALKING DEAD",
      amount: null,
      rarity: null,
      img_uri: "img/characters/ZOMBIE.png",
    },
  ],
    ears: [
    {
      index: "EARS 01",
      itemName: "BATMAN",
      amount: 444.40000000000003,
      rarity: 0.05,
      img_uri: "img/ears/batman.png",
    },
    {
      index: "EARS 02",
      itemName: "BITTEN OFF",
      amount: 888.8000000000001,
      rarity: 0.1,
      img_uri: "img/ears/bitten.png",
    },
    {
      index: "EARS 03",
      itemName: "CARDS",
      amount: 888.8000000000001,
      rarity: 0.1,
      img_uri: "img/ears/cards.png",
    },
    {
      index: "EARS 04",
      itemName: "DEFAULT",
      amount: 1777.6000000000001,
      rarity: 0.2,
      img_uri: "img/ears/regular.png",
    },
    {
      index: "EARS 05",
      itemName: "EARPLUGS",
      amount: 444.40000000000003,
      rarity: 0.05,
      img_uri: "img/ears/ear-plugs.png",
    },
    {
      index: "EARS 06",
      itemName: "EARRINGS",
      amount: 888.8000000000001,
      rarity: 0.1,
      img_uri: "img/ears/earrings.png",
    },
    {
      index: "EARS 07",
      itemName: "INK DIPPED",
      amount: 444.40000000000003,
      rarity: 0.05,
      img_uri: "img/ears/musk.png",
    },
    {
      index: "EARS 08",
      itemName: "KILL COUNT",
      amount: 444.40000000000003,
      rarity: 0.05,
      img_uri: "img/ears/kill-count.png",
    },
    {
      index: "EARS 09",
      itemName: "LAME BOTH",
      amount: 444.40000000000003,
      rarity: 0.05,
      img_uri: "img/ears/lame-ears.png",
    },
    {
      index: "EARS 10",
      itemName: "LAME LEFT",
      amount: 711.04,
      rarity: 0.08,
      img_uri: "img/ears/lame-ear.png",
    },
    {
      index: "EARS 11",
      itemName: "LUCKY STAR",
      amount: 88.88,
      rarity: 0.01,
      img_uri: "img/ears/lucky-star.png",
    },
    {
      index: "EARS 12",
      itemName: "ARMY BAND",
      amount: 177.76,
      rarity: 0.02,
      img_uri: "img/ears/nazi-band.png",
    },
    {
      index: "EARS 13",
      itemName: "POTATO HEAD",
      amount: 444.40000000000003,
      rarity: 0.05,
      img_uri: "img/ears/potato-head.png",
    },
    {
      index: "EARS 14",
      itemName: "SPIRAL",
      amount: 444.40000000000003,
      rarity: 0.05,
      img_uri: "img/ears/kratos-tattoo.png",
    },
    {
      index: "EARS 15",
      itemName: "YODA",
      amount: 177.76,
      rarity: 0.02,
      img_uri: "img/ears/yoda.png",
    },
    {
      index: "EARS 16",
      itemName: "VAMPIRE",
      amount: 177.76,
      rarity: 0.02,
      img_uri: "img/ears/vampire-ears.png",
    },
  ],
  mouth: [
    {
      index: "MOUTH 01",
      itemName: "BIG SMILE",
      amount: 888.8000000000001,
      rarity: 0.095,
      img_uri: "img/mouth/big-smile.png",
    },
    {
      index: "MOUTH 02",
      itemName: "BUGS BUNNY",
      amount: 355.52,
      rarity: 0.038,
      img_uri: "img/mouth/bugs-bunny.png",
    },
    {
      index: "MOUTH 03",
      itemName: "DEFAULT",
      amount: 1777.6000000000001,
      rarity: 0.19,
      img_uri: "img/mouth/default.png",
    },
    {
      index: "MOUTH 04",
      itemName: "HANNIBAL",
      amount: 177.76,
      rarity: 0.019,
      img_uri: "img/mouth/hannibal.png",
    },
    {
      index: "MOUTH 05",
      itemName: "JOINT",
      amount: 444.40000000000003,
      rarity: 0.0475,
      img_uri: "img/mouth/joint.png",
    },
    {
      index: "MOUTH 06",
      itemName: "JOKER",
      amount: 177.76,
      rarity: 0.019,
      img_uri: "img/mouth/joker.png",
    },
    {
      index: "MOUTH 07",
      itemName: "MUNCHING",
      amount: 355.52,
      rarity: 0.038,
      img_uri: "img/mouth/munching.png",
    },
    {
      index: "MOUTH 08",
      itemName: "RAINBOW",
      amount: 533.28,
      rarity: 0.056999999999999995,
      img_uri: "img/mouth/rainbow.png",
    },
    {
      index: "MOUTH 09",
      itemName: "SAW PUPPET",
      amount: 888.8000000000001,
      rarity: 0.095,
      img_uri: "img/mouth/saw-puppet.png",
    },
    {
      index: "MOUTH 10",
      itemName: "SLIGHTLY OPEN",
      amount: 355.52,
      rarity: 0.038,
      img_uri: "img/mouth/slightly-open.png",
    },
    {
      index: "MOUTH 11",
      itemName: "SMILE",
      amount: 888.8000000000001,
      rarity: 0.095,
      img_uri: "img/mouth/smile.png",
    },
    {
      index: "MOUTH 12",
      itemName: "SMIRK",
      amount: 444.40000000000003,
      rarity: 0.0475,
      img_uri: "img/mouth/smirk.png",
    },
    {
      index: "MOUTH 13",
      itemName: "SNAKE TONGUE",
      amount: 355.52,
      rarity: 0.038,
      img_uri: "img/mouth/snake-tongue.png",
    },
    {
      index: "MOUTH 14",
      itemName: "STICKING TONGUE",
      amount: 355.52,
      rarity: 0.038,
      img_uri: "img/mouth/sticking-tongue.png",
    },
    {
      index: "MOUTH 15",
      itemName: "VAMPIRE",
      amount: 177.76,
      rarity: 0.019,
      img_uri: "img/mouth/vampire.png",
    },
    {
      index: "MOUTH 16",
      itemName: "VENOM",
      amount: 355.52,
      rarity: 0.038,
      img_uri: "img/mouth/venom.png",
    },
    {
      index: "MOUTH 17",
      itemName: "VOMIT",
      amount: 355.52,
      rarity: 0.038,
      img_uri: "img/mouth/vomit.png",
    },
  ],
  feet: [
    {
      index: "FEET 01",
      itemName: "BALL AND CHAINS",
      amount: 711.04,
      rarity: 0.08,
      img_uri: "img/feet/ball-chains.png",
    },
    {
      index: "FEET 02",
      itemName: "BLACK SHOES",
      amount: 1066.56,
      rarity: 0.12,
      img_uri: "img/feet/black-shoes.png",
    },
    {
      index: "FEET 03",
      itemName: "CHICKEN LEGS",
      amount: 888.8000000000001,
      rarity: 0.1,
      img_uri: "img/feet/chicken-legs.png",
    },
    {
      index: "FEET 04",
      itemName: "DEFAULT",
      amount: 2222,
      rarity: 0.25,
      img_uri: "img/feet/regular.png",
    },
    {
      index: "FEET 05",
      itemName: "GETA",
      amount: 888.8000000000001,
      rarity: 0.1,
      img_uri: "img/feet/geta.png",
    },
    {
      index: "FEET 06",
      itemName: "ICE SKATES",
      amount: 888.8000000000001,
      rarity: 0.1,
      img_uri: "img/feet/ice-skates.png",
    },
    {
      index: "FEET 07",
      itemName: "LIZARD LEGS",
      amount: 888.8000000000001,
      rarity: 0.1,
      img_uri: "img/feet/lizard-legs.png",
    },
    {
      index: "FEET 08",
      itemName: "LUCKY STAR",
      amount: 444.40000000000003,
      rarity: 0.05,
      img_uri: "img/feet/lucky-star.png",
    },
    {
      index: "FEET 09",
      itemName: "ROLLER SKATES",
      amount: 888.8000000000001,
      rarity: 0.1,
      img_uri: "img/feet/roller-skates.png",
    },
  ],
  "Item Held (Full)": [
    {
      index: "ITEM FULL 01",
      itemName: "C3PO",
      amount: 266.64,
      rarity: 0.03,
      img_uri: "img/itemfull/c3po.png",
    },
    {
      index: "ITEM FULL 02",
      itemName: "BOMB",
      amount: 266.64,
      rarity: 0.03,
      img_uri: "img/itemfull/bomb.png",
    },
    {
      index: "ITEM FULL 03",
      itemName: "BOWLING BALL",
      amount: 266.64,
      rarity: 0.03,
      img_uri: "img/itemfull/bowling-ball.png",
    },
    {
      index: "ITEM FULL 04",
      itemName: "BITCOIN",
      amount: 266.64,
      rarity: 0.03,
      img_uri: "img/itemfull/btc-coin.png",
    },
    {
      index: "ITEM FULL 05",
      itemName: "CHEST EMPTY",
      amount: 266.64,
      rarity: 0.03,
      img_uri: "img/itemfull/treasure-chest-empty.png",
    },
    {
      index: "ITEM FULL 06",
      itemName: "CHEST GOLD",
      amount: 266.64,
      rarity: 0.03,
      img_uri: "img/itemfull/treasure-chest-gold.png",
    },
    {
      index: "ITEM FULL 07",
      itemName: "CLOCK",
      amount: 266.64,
      rarity: 0.03,
      img_uri: "img/itemfull/groundhog-day.png",
    },
    {
      index: "ITEM FULL 08",
      itemName: "COMPANION CUBE",
      amount: 266.64,
      rarity: 0.03,
      img_uri: "img/itemfull/companion-cube.png",
    },
    {
      index: "ITEM FULL 09",
      itemName: "COOKIE WHOLE",
      amount: 266.64,
      rarity: 0.03,
      img_uri: "img/itemfull/cookie-whole.png",
    },
    {
      index: "ITEM FULL 10",
      itemName: "CUE BALL",
      amount: 266.64,
      rarity: 0.03,
      img_uri: "img/itemfull/cue-ball.png",
    },
    {
      index: "ITEM FULL 11",
      itemName: "DONUT",
      amount: 266.64,
      rarity: 0.03,
      img_uri: "img/itemfull/doughnut.png",
    },
    {
      index: "ITEM FULL 12",
      itemName: "DRUM",
      amount: 266.64,
      rarity: 0.03,
      img_uri: "img/itemfull/drum.png",
    },
    {
      index: "ITEM FULL 13",
      itemName: "GOLDEN SNITCH",
      amount: 266.64,
      rarity: 0.03,
      img_uri: "img/itemfull/golden-snitch.png",
    },
    {
      index: "ITEM FULL 14",
      itemName: "MAGIC 8 BALL",
      amount: 266.64,
      rarity: 0.03,
      img_uri: "img/itemfull/magic-ball.png",
    },
    {
      index: "ITEM FULL 15",
      itemName: "MOON",
      amount: 266.64,
      rarity: 0.03,
      img_uri: "img/itemfull/full-moon.png",
    },
    {
      index: "ITEM FULL 16",
      itemName: "NOKIA",
      amount: 266.64,
      rarity: 0.03,
      img_uri: "img/itemfull/nokia-brick.png",
    },
    {
      index: "ITEM FULL 17",
      itemName: "POKEBALL",
      amount: 266.64,
      rarity: 0.03,
      img_uri: "img/itemfull/pokeball.png",
    },
    {
      index: "ITEM FULL 18",
      itemName: "RUBIK",
      amount: 266.64,
      rarity: 0.03,
      img_uri: "img/itemfull/rubik.png",
    },
    {
      index: "ITEM FULL 19",
      itemName: "TEDDY BEAR",
      amount: 266.64,
      rarity: 0.03,
      img_uri: "img/itemfull/teddy-bear.png",
    },
    {
      index: "ITEM FULL 20",
      itemName: "XENO EGG",
      amount: 266.64,
      rarity: 0.03,
      img_uri: "img/itemfull/xeno-egg.png",
    },
  ],
  "Item Held (Partial)": [
    {
      index: "ITEM PARTIAL 01",
      itemName: "BOOK",
      amount: 248.86400000000003,
      rarity: 0.028000000000000004,
      img_uri: "img/itempart/mein-kampf.png",
    },
    {
      index: "ITEM PARTIAL 02",
      itemName: "CANDLE",
      amount: 248.86400000000003,
      rarity: 0.028000000000000004,
      img_uri: "img/itempart/candle.png",
    },
    {
      index: "ITEM PARTIAL 03",
      itemName: "CARROT",
      amount: 248.86400000000003,
      rarity: 0.028000000000000004,
      img_uri: "img/itempart/carrot.png",
    },
    {
      index: "ITEM PARTIAL 04",
      itemName: "COCONUT MILK",
      amount: 248.86400000000003,
      rarity: 0.028000000000000004,
      img_uri: "img/itempart/coconut-drink.png",
    },
    {
      index: "ITEM PARTIAL 05",
      itemName: "COLD STORAGE",
      amount: 248.86400000000003,
      rarity: 0.028000000000000004,
      img_uri: "img/itempart/cold-storage.png",
    },
    {
      index: "ITEM PARTIAL 06",
      itemName: "COOKIE EATEN",
      amount: 248.86400000000003,
      rarity: 0.028000000000000004,
      img_uri: "img/itempart/cookie-eaten.png",
    },
    {
      index: "ITEM PARTIAL 07",
      itemName: "HAPPY PILL",
      amount: 213.312,
      rarity: 0.024,
      img_uri: "img/itempart/happy-pill.png",
    },
    {
      index: "ITEM PARTIAL 08",
      itemName: "HOT CHOCOLATE",
      amount: 248.86400000000003,
      rarity: 0.028000000000000004,
      img_uri: "img/itempart/hot-chocolate.png",
    },
    {
      index: "ITEM PARTIAL 09",
      itemName: "ICE BOX",
      amount: 248.86400000000003,
      rarity: 0.028000000000000004,
      img_uri: "img/itempart/icebox.png",
    },
    {
      index: "ITEM PARTIAL 10",
      itemName: "PLAYBOY",
      amount: 248.86400000000003,
      rarity: 0.028000000000000004,
      img_uri: "img/itempart/btc-magazine.png",
    },
    {
      index: "ITEM PARTIAL 11",
      itemName: "SAD PILL",
      amount: 213.312,
      rarity: 0.024,
      img_uri: "img/itempart/sad-pill.png",
    },
    {
      index: "ITEM PARTIAL 12",
      itemName: "SHAKE BLUE",
      amount: 213.312,
      rarity: 0.024,
      img_uri: "img/itempart/shake-green-2.png",
    },
    {
      index: "ITEM PARTIAL 13",
      itemName: "SHAKE GREEN",
      amount: 213.312,
      rarity: 0.024,
      img_uri: "img/itempart/shake-green.png",
    },
    {
      index: "ITEM PARTIAL 14",
      itemName: "SHAKE PURPLE",
      amount: 213.312,
      rarity: 0.024,
      img_uri: "img/itempart/shake-green-1.png",
    },
    {
      index: "ITEM PARTIAL 15",
      itemName: "TEA",
      amount: 248.86400000000003,
      rarity: 0.028000000000000004,
      img_uri: "img/itempart/btc-tea.png",
    },
  ],
  paws: [
    {
      index: "PAWS 01",
      itemName: "BLACK GLOVES",
      amount: 444.40000000000003,
      rarity: 0.05,
      img_uri: "img/paws/black-gloves.png",
    },
    {
      index: "PAWS 02",
      itemName: "BLOODY GLOVES",
      amount: 444.40000000000003,
      rarity: 0.05,
      img_uri: "img/paws/bloddy-brown.png",
    },
    {
      index: "PAWS 03",
      itemName: "BOXING GLOVES BLUE",
      amount: 444.40000000000003,
      rarity: 0.05,
      img_uri: "img/paws/boxing-gloves-blue.png",
    },
    {
      index: "PAWS 04",
      itemName: "BOXING GLOVES RED",
      amount: 444.40000000000003,
      rarity: 0.05,
      img_uri: "img/paws/boxing-gloves-red.png",
    },
    {
      index: "PAWS 05",
      itemName: "CLAWS",
      amount: 444.40000000000003,
      rarity: 0.05,
      img_uri: "img/paws/claws.png",
    },
    {
      index: "PAWS 06",
      itemName: "DEFAULT",
      amount: 1777.6000000000001,
      rarity: 0.2,
      img_uri: "img/paws/regular.png",
    },
    {
      index: "PAWS 07",
      itemName: "F OFF",
      amount: 444.40000000000003,
      rarity: 0.05,
      img_uri: "img/paws/f-off.png",
    },
    {
      index: "PAWS 08",
      itemName: "FIGHTING GLOVES",
      amount: 888.8000000000001,
      rarity: 0.1,
      img_uri: "img/paws/fighting-gloves.png",
    },
    {
      index: "PAWS 09",
      itemName: "GRAVITY GUN",
      amount: 444.40000000000003,
      rarity: 0.05,
      img_uri: "img/paws/gravity-gun.png",
    },
    {
      index: "PAWS 10",
      itemName: "HAIRY",
      amount: 888.8000000000001,
      rarity: 0.1,
      img_uri: "img/paws/hairy.png",
    },
    {
      index: "PAWS 11",
      itemName: "HOOK",
      amount: 444.40000000000003,
      rarity: 0.05,
      img_uri: "img/paws/hook.png",
    },
    {
      index: "PAWS 12",
      itemName: "NAILS",
      amount: 711.04,
      rarity: 0.08,
      img_uri: "img/paws/nails.png",
    },
    {
      index: "PAWS 13",
      itemName: "OLD SPOTTED",
      amount: 177.76,
      rarity: 0.02,
      img_uri: "img/paws/old-spotted.png",
    },
    {
      index: "PAWS 14",
      itemName: "SONIC KLAW",
      amount: 444.40000000000003,
      rarity: 0.05,
      img_uri: "img/paws/sonic-klaw.png",
    },
    {
      index: "PAWS 15",
      itemName: "WRIST LOJACK-A-MATER",
      amount: 444.40000000000003,
      rarity: 0.05,
      img_uri: "img/paws/wrist-lojack-a-mater.png",
    },
  ],
  masks: [
    {
      index: "MASK 01",
      itemName: "ANON",
      amount: 66.66000000000001,
      rarity: 0.0075,
      img_uri: "img/masks/anon.png",
    },
    {
      index: "MASK 02",
      itemName: "CRAZY PEPE",
      amount: 66.66000000000001,
      rarity: 0.0075,
      img_uri: "img/masks/crazy-pepe.png",
    },
    {
      index: "MASK 03",
      itemName: "DONNIE DARKO",
      amount: 44.440000000000005,
      rarity: 0.005000000000000001,
      img_uri: "img/masks/donnie-darko.png",
    },
    {
      index: "MASK 04",
      itemName: "SPIRITTED AWAY",
      amount: 44.440000000000005,
      rarity: 0.005000000000000001,
      img_uri: "img/masks/spirited-away.png",
    },
    {
      index: "MASK 05",
      itemName: "SQUID MANAGER",
      amount: 22.220000000000002,
      rarity: 0.0025000000000000005,
      img_uri: "img/masks/squid-manager.png",
    },
    {
      index: "MASK 06",
      itemName: "SQUID SOLDIER",
      amount: 88.88000000000001,
      rarity: 0.010000000000000002,
      img_uri: "img/masks/squid-soldier.png",
    },
    {
      index: "MASK 07",
      itemName: "SQUID STAFF",
      amount: 111.10000000000001,
      rarity: 0.0125,
      img_uri: "img/masks/squid-staff.png",
    },
  ],
  eyes: [
    {
      index: "EYES 01",
      itemName: "BIG LEBOWSKI",
      amount: 355.52,
      rarity: 0.038,
      img_uri: "img/eyes/big-lebowski.png",
    },
    {
      index: "EYES 02",
      itemName: "BLOODSHOT",
      amount: 266.64,
      rarity: 0.028499999999999998,
      img_uri: "img/eyes/bloodshot.png",
    },
    {
      index: "EYES 03",
      itemName: "COLD STARE",
      amount: 444.40000000000003,
      rarity: 0.0475,
      img_uri: "img/eyes/cold-stare.png",
    },
    {
      index: "EYES 04",
      itemName: "COOL SHADES",
      amount: 266.64,
      rarity: 0.028499999999999998,
      img_uri: "img/eyes/cool-shades.png",
    },
    {
      index: "EYES 05",
      itemName: "CUTESY",
      amount: 266.64,
      rarity: 0.028499999999999998,
      img_uri: "img/eyes/playing-cutesy.png",
    },
    {
      index: "EYES 06",
      itemName: "CYCLOPS VISOR",
      amount: 177.76,
      rarity: 0.019,
      img_uri: "img/eyes/cyclops-visor.png",
    },
    {
      index: "EYES 07",
      itemName: "DEAD SERIOUS",
      amount: 444.40000000000003,
      rarity: 0.0475,
      img_uri: "img/eyes/dead-serious.png",
    },
    {
      index: "EYES 08",
      itemName: "DECEASED",
      amount: 355.52,
      rarity: 0.038,
      img_uri: "img/eyes/eyes-deceased.png",
    },
    {
      index: "EYES 09",
      itemName: "DEFAULT",
      amount: 1333.2,
      rarity: 0.1425,
      img_uri: "img/eyes/original-rabbit.png",
    },
    {
      index: "EYES 10",
      itemName: "DISORIENTED",
      amount: 444.40000000000003,
      rarity: 0.0475,
      img_uri: "img/eyes/disoriented.png",
    },
    {
      index: "EYES 11",
      itemName: "DRAGON",
      amount: 444.40000000000003,
      rarity: 0.0475,
      img_uri: "img/eyes/dragon.png",
    },
    {
      index: "EYES 12",
      itemName: "GLASSY",
      amount: 266.64,
      rarity: 0.028499999999999998,
      img_uri: "img/eyes/glassy-look.png",
    },
    {
      index: "EYES 13",
      itemName: "HOLLOW",
      amount: 444.40000000000003,
      rarity: 0.0475,
      img_uri: "img/eyes/hollow-scare.png",
    },
    {
      index: "EYES 14",
      itemName: "HYPNO TOAD",
      amount: 266.64,
      rarity: 0.028499999999999998,
      img_uri: "img/eyes/hypno-toad.png",
    },
    {
      index: "EYES 15",
      itemName: "IN LOVE",
      amount: 266.64,
      rarity: 0.028499999999999998,
      img_uri: "img/eyes/in-love.png",
    },
    {
      index: "EYES 16",
      itemName: "MORPHEUS",
      amount: 444.40000000000003,
      rarity: 0.0475,
      img_uri: "img/eyes/morpheus.png",
    },
    {
      index: "EYES 17",
      itemName: "ORIGINAL MODIFIED",
      amount: 444.40000000000003,
      rarity: 0.0475,
      img_uri: "img/eyes/original-modified.png",
    },
    {
      index: "EYES 18",
      itemName: "PANDA",
      amount: 444.40000000000003,
      rarity: 0.0475,
      img_uri: "img/eyes/panda.png",
    },
    {
      index: "EYES 19",
      itemName: "READING GLASSES",
      amount: 266.64,
      rarity: 0.028499999999999998,
      img_uri: "img/eyes/reading-glasses.png",
    },
    {
      index: "EYES 20",
      itemName: "SIDE GLANCE",
      amount: 266.64,
      rarity: 0.028499999999999998,
      img_uri: "img/eyes/side-glance.png",
    },
    {
      index: "EYES 21",
      itemName: "SMALL SAD",
      amount: 266.64,
      rarity: 0.028499999999999998,
      img_uri: "img/eyes/small-sad.png",
    },
    {
      index: "EYES 22",
      itemName: "TEARY",
      amount: 266.64,
      rarity: 0.028499999999999998,
      img_uri: "img/eyes/teary.png",
    },
    {
      index: "EYES 23",
      itemName: "UNRECOGNIZED",
      amount: 444.40000000000003,
      rarity: 0.0475,
      img_uri: "img/eyes/unrecognized.png",
    },
  ],
  forehead: [
    {
      index: "FOREHEAD 01",
      itemName: "AVATAR",
      amount: 444.40000000000003,
      rarity: 0.0475,
      img_uri: "img/forehead/avatar-anime.png",
    },
    {
      index: "FOREHEAD 02",
      itemName: "BARCODE",
      amount: 444.40000000000003,
      rarity: 0.0475,
      img_uri: "img/forehead/barcode.png",
    },
    {
      index: "FOREHEAD 03",
      itemName: "BLOODY SCALP",
      amount: 177.76,
      rarity: 0.019,
      img_uri: "img/forehead/bloody-scalp.png",
    },
    {
      index: "FOREHEAD 04",
      itemName: "CARTMAN",
      amount: 355.52,
      rarity: 0.038,
      img_uri: "img/forehead/CARTMAN.png",
    },
    {
      index: "FOREHEAD 05",
      itemName: "COLOR HORN",
      amount: 444.40000000000003,
      rarity: 0.0475,
      img_uri: "img/forehead/unicorn-colour.png",
    },
    {
      index: "FOREHEAD 06",
      itemName: "CURL",
      amount: 266.64,
      rarity: 0.028499999999999998,
      img_uri: "img/forehead/curl.png",
    },
    {
      index: "FOREHEAD 07",
      itemName: "DEFAULT",
      amount: 1333.2,
      rarity: 0.1425,
      img_uri: "img/forehead/default.png",
    },
    {
      index: "FOREHEAD 08",
      itemName: "DEVIL HORNS",
      amount: 444.40000000000003,
      rarity: 0.0475,
      img_uri: "img/forehead/devil-horns.png",
    },
    {
      index: "FOREHEAD 09",
      itemName: "HARRY SCAR",
      amount: 266.64,
      rarity: 0.028499999999999998,
      img_uri: "img/forehead/POTTER-SCAR.png",
    },
    {
      index: "FOREHEAD 10",
      itemName: "ICE CREAM",
      amount: 444.40000000000003,
      rarity: 0.0475,
      img_uri: "img/forehead/ice-cream.png",
    },
    {
      index: "FOREHEAD 11",
      itemName: "LINES",
      amount: 177.76,
      rarity: 0.019,
      img_uri: "img/forehead/three-lines.png",
    },
    {
      index: "FOREHEAD 12",
      itemName: "LOKI",
      amount: 266.64,
      rarity: 0.028499999999999998,
      img_uri: "img/forehead/loki.png",
    },
    {
      index: "FOREHEAD 13",
      itemName: "NINJA BANDANA",
      amount: 444.40000000000003,
      rarity: 0.0475,
      img_uri: "img/forehead/ninja-bandana.png",
    },
    {
      index: "FOREHEAD 14",
      itemName: "PUNK BLUE",
      amount: 266.64,
      rarity: 0.028499999999999998,
      img_uri: "img/forehead/punk-blue.png",
    },
    {
      index: "FOREHEAD 15",
      itemName: "PUNK GREEN",
      amount: 266.64,
      rarity: 0.028499999999999998,
      img_uri: "img/forehead/punk-green.png",
    },
    {
      index: "FOREHEAD 16",
      itemName: "PUNK PURPLE",
      amount: 266.64,
      rarity: 0.028499999999999998,
      img_uri: "img/forehead/punk-mohawk.png",
    },
    {
      index: "FOREHEAD 17",
      itemName: "PUNK YELLOW",
      amount: 266.64,
      rarity: 0.028499999999999998,
      img_uri: "img/forehead/punk-yellow.png",
    },
    {
      index: "FOREHEAD 18",
      itemName: "RABBI",
      amount: 444.40000000000003,
      rarity: 0.0475,
      img_uri: "img/forehead/rabbi.png",
    },
    {
      index: "FOREHEAD 19",
      itemName: "SANTA",
      amount: 355.52,
      rarity: 0.038,
      img_uri: "img/forehead/SANTA.png",
    },
    {
      index: "FOREHEAD 20",
      itemName: "SYMBOL",
      amount: 177.76,
      rarity: 0.019,
      img_uri: "img/forehead/swastika.png",
    },
    {
      index: "FOREHEAD 21",
      itemName: "TARGET",
      amount: 266.64,
      rarity: 0.028499999999999998,
      img_uri: "img/forehead/target.png",
    },
    {
      index: "FOREHEAD 22",
      itemName: "TEABAGGER",
      amount: 266.64,
      rarity: 0.028499999999999998,
      img_uri: "img/forehead/teabagger.png",
    },
    {
      index: "FOREHEAD 23",
      itemName: "THIRD EYE",
      amount: 444.40000000000003,
      rarity: 0.0475,
      img_uri: "img/forehead/third-eye.png",
    },
    {
      index: "FOREHEAD 24",
      itemName: "UNDERCUT",
      amount: 266.64,
      rarity: 0.028499999999999998,
      img_uri: "img/forehead/undercut.png",
    },
    {
      index: "FOREHEAD 25",
      itemName: "WHITE HORN",
      amount: 88.88,
      rarity: 0.0095,
      img_uri: "img/forehead/unicorn-white.png",
    },
  ],

};
