import img1 from '../../assets/img/vote.png'
import vector from "../../assets/svg/Vector 3.svg";

export const Location = (props) => {

    const {capital,country,show,fade} = props.props


    return (
        <>
            <div className="location text-center">
                <div className="top">
                        <div className="located">
                            <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill="white" d="M14.1224 9.44892V21.5137C18.4652 20.9701 21.9068 17.5105 22.448 13.1473H18.9224L23.3672 6.00012C24.3898 7.8126 24.9256 9.85904 24.9224 11.9401C24.9224 18.6001 19.55 24.0001 12.9224 24.0001C6.29476 24.0001 0.922363 18.6001 0.922363 11.9401C0.922363 9.78012 1.48756 7.75332 2.47756 6.00012L6.92236 13.1473H3.39676C3.93796 17.5105 7.38076 20.9701 11.7224 21.5137V9.44892C10.5938 9.15405 9.61109 8.45849 8.9578 7.49212C8.30451 6.52575 8.02528 5.35464 8.17224 4.19746C8.3192 3.04028 8.88231 1.97615 9.75643 1.20376C10.6305 0.431368 11.7559 0.00352762 12.9224 0.000124417C14.0915 -0.00103108 15.2208 0.424545 16.0984 1.19697C16.976 1.9694 17.5416 3.03558 17.6889 4.19538C17.8362 5.35518 17.5551 6.52887 16.8984 7.49615C16.2418 8.46343 15.2547 9.1578 14.1224 9.44892ZM12.9224 7.20012C13.5589 7.20012 14.1693 6.94727 14.6194 6.49718C15.0695 6.04709 15.3224 5.43664 15.3224 4.80012C15.3224 4.1636 15.0695 3.55315 14.6194 3.10307C14.1693 2.65298 13.5589 2.40012 12.9224 2.40012C12.2858 2.40012 11.6754 2.65298 11.2253 3.10307C10.7752 3.55315 10.5224 4.1636 10.5224 4.80012C10.5224 5.43664 10.7752 6.04709 11.2253 6.49718C11.6754 6.94727 12.2858 7.20012 12.9224 7.20012Z" />
                            </svg>  

                        </div>
                   
                    
                    
                </div>
                <div className="middle d-flex justify-content-center col-gap-2 align-center"> 
                    {/* <span> */}
                        <img className="rotate-180deg" src={vector} alt="vote" />
                    {/* </span> */}
                    {/* {show ?(
                        <span className="location-name" >
                            {show ? `${country}, ${capital}` : ''}
                        </span> 
                    ):(
                        <span className="select-adventure" >Select Existing Adventure</span>
                    ) } */}

                    <span className="select-adventure" >
                        <span className={!show ? 'active' : 'deactive'}>
                            {show ? `` : 'Select Existing Adventure'}
                        </span>
                        <span className={fade ? 'active' : 'deactive'}>
                            {show ? `${country}, ${capital}` : ''}
                        </span>
                        {/* Select Existing Adventure */}
                    </span>

                    {/* <span> */}
                        <img src={vector} alt="vote" />
                    {/* </span> */}
                </div>
                <div className="bottom">
                    {/* {show ?( */}
                        <div className="bubbles d-flex col-gap-1 align-center justify-content-center ">
                            <span className="circle wpx-2 rounded-50">
                            </span>
                            <span className="circle wpx-4 rounded-50">
                            </span>
                            <span className="circle wpx-6 rounded-50">
                            </span>
                            <span className="circle wpx-4 rounded-50">
                            </span>
                            <span className="circle wpx-2 rounded-50">
                            </span>
                        </div>
                    {/* ) :( */}
                        {/* <p className="mb-0 mt-1 select-adventure"> */}
                            {/* Select Existing Adventure  */}
                        {/* </p> */}
                    {/* )} */}
                        
                    
                </div>
            </div>
        </>
    )
}