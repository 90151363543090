import React, { useContext, useState } from "react";
import UserContext from "../../../../utils/contexts/User";
import "./style.css";

export const AsideItem = (props) => {
  let { galleryTag, setGalleryTag } = useContext(UserContext);

  const [check, setCheck] = useState(false);
  // list = arr
  let items, dropList, svg;
  const { list, title } = props;
  if (list) {
    items = list.map((el, index) => (
      <li className="aside__list-item-drop-item" key={index}>
        {el}
      </li>
    ));
    dropList = <ul className="aside__list-item-drop-list">{items} </ul>;

    checkState();
  }

  let handleClick = (e) => {
    setGalleryTag({ ...galleryTag, menu: title });
  };

  function checkState() {
    if (check) {
      svg = (
        <svg
          width="14"
          height="2"
          viewBox="0 0 14 2"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M6 0H8H14V2H8H6H0V0H6Z" fill="white" />
        </svg>
      );
    } else {
      svg = (
        <svg
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6 6V0H8V6H14V8H8V14H6V8H0V6H6Z"
            fill="rgba(255,255,255,0.4)"
          />
        </svg>
      );
    }
  }

  return (
    <>
      <li className="aside__list-item">
        <div className="aside__list-item-inner" onClick={handleClick}>
          <span className="aside__list-item-title">{title}</span>
          <span className="aside__list-item-svg">{svg}</span>
        </div>
        {dropList}
      </li>
    </>
  );
};
