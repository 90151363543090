import Web3 from 'web3';
import moonRabbitJsonDev from '../abis/dev/RadRabbits.json';
import { moonRabbitContractAddress } from './constants/variables';

export const initWeb3 = async () => {
  if (window.ethereum) {
    return new Web3(window.ethereum);
  } else {
    return null;
    // window.alert('Non-Ethereum browser detected. You should consider trying MetaMask!!!');
  }
};

export const getActiveAccount = async () => {
  const web3 = window.ethereum;
  if (web3) {
    const web3 = new Web3(window.ethereum);
    const accounts = await web3.eth.getAccounts();
    return accounts[0] ?? null;
  } else {
    window.alert('Non-Ethereum browser detected. You should consider trying MetaMask!');
  }
};

export const getEthBalance = async (address) => {
  const web3 = window.ethereum;
  if (web3) {
    const web3 = new Web3(window.ethereum);
    const amount = await web3.eth.getBalance(address);
    return parseFloat(web3.utils.fromWei(amount)).toFixed(2) ?? 0;
  } else {
    window.alert('Non-Ethereum browser detected. You should consider trying MetaMask!');
  }
};

export const getContractAbi = () => {
  return process.env.NODE_ENV === 'development'
    ? {
        moonRabbitJson: moonRabbitJsonDev,
      }
    : {
        moonRabbitJson: moonRabbitJsonDev,
      };
};

export const createContract = () => {
  if (window.ethereum) {
    const web3 = new Web3(window.ethereum);
    return new web3.eth.Contract(getContractAbi().moonRabbitJson.abi, moonRabbitContractAddress);
  } else {
    return null;
  }
};

export const metamaskEnabled = async () => {
  if (window.ethereum) {
    window.web3 = new Web3(window.ethereum);
    await window.ethereum.enable();
  } else {
    window.alert('Non-Ethereum browser detected. You should consider trying MetaMask!');
  }
};
