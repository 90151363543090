import jQuery from 'jquery';
import React from 'react';
import Swiper from 'swiper';
import bunnySrc1 from '../../../../assets/img/bunny-showcase-1.png';
import bunnySrc2 from '../../../../assets/img/bunny-showcase-2.png';
import bunnySrc3 from '../../../../assets/img/bunny-showcase-3.png';

const HeroesSlider = () => {
  React.useEffect(() => {
    const swiper1 = new Swiper('.heroes_image', {
      loop: false,

      autoplay: {
        delay: 3000,
        disableOnInteraction: false,
      },

      breakpoints: {
        320: {
          slidesPerView: 3,
          spaceBetween: 150,
          centeredSlides: true,
        },
        1200: {
          slidesPerView: 3,
          spaceBetween: 94,
          centeredSlides: false,
        },
        2600: {
          slidesPerView: 3,
          spaceBetween: 30,
          centeredSlides: false,
        },
      },
      // modules: [Navigation, Pagination],
    });

    const swiper2 = new Swiper('.heroes_text', {
      loop: false,
      centeredSlides: false,
      spaceBetween: 30,

      breakpoints: {
        320: {
          slidesPerView: 1,
          spaceBetween: 150,
          centeredSlides: true,
        },
        1200: {
          slidesPerView: 3,
          spaceBetween: 80,
          centeredSlides: false,
        },
        2600: {
          slidesPerView: 3,
          spaceBetween: 30,
          centeredSlides: false,
        },
      },

      thumbs: {
        swiper: swiper1,
      },
      autoplay: {
        delay: 3000,
        disableOnInteraction: false,
      },
    });

    swiper1.on('slideChange', function () {
      swiper2.slideToLoop(this.realIndex);
    });
    swiper2.on('slideChange', function () {
      swiper1.slideToLoop(this.realIndex);
    });

    const slides_count = jQuery('.heroes_image .swiper-slide').attr('aria-label');

    if (slides_count) {
      const slides_count_item = slides_count.split('/')[1];
      const screen_width = window.screen.width;
      if (slides_count_item <= 3 && screen_width >= 1200) {
        swiper1.disable();
        swiper2.disable();
      }
    }
  }, []);

  return (
    <div className="heroes">
      <div className="heroes_image slider-container container">
        <div className="swiper-wrapper heroes_image_wrapper">
          <div className="swiper-slide">
            <img className="heroes__img" src={bunnySrc1} alt="" />
          </div>
          <div className="swiper-slide">
            <img className="heroes__img" src={bunnySrc2} alt="" />
          </div>
          <div className="swiper-slide">
            <img className="heroes__img" src={bunnySrc3} alt="" />
          </div>
        </div>
      </div>
      <div className="heroes_text slider-container container">
        <div className="heroes__wrapper swiper-wrapper">
          <div className="heroes__slide swiper-slide">
            <h2 className="heroes__title">They Age</h2>
            <p className="heroes__text">
              <span>
                In hopes of controlling their population, we have introduced an aging algorithm into each NFT.
              </span>
              Rad Rabbits will age, wither and ultimately die if you do nothing.
            </p>
          </div>
          <div className="heroes__slide swiper-slide">
            <h2 className="heroes__title">They Sin</h2>
            <p className="heroes__text">
              <span>Rad Rabbits have unique personalities and act independently. </span>
              The Conglomerate is in no way, shape or form responsible for their actions once released into the wild.
            </p>
          </div>
          <div className="heroes__slide swiper-slide">
            <h2 className="heroes__title">They Survive</h2>
            <p className="heroes__text">
              <span>Never underestimate the power of self-preseveration. </span>
              Rad Rabbits will attack and possibly kill each other if they feel their own safety is at stake.
            </p>
          </div>
        </div>
      </div>
              <div className="swiper-button-prev"></div>
              <div className="swiper-button-next"></div>
    </div>
  );
};

export default HeroesSlider;
