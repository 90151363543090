import { useEffect, useState } from "react"
import { ModalContentV1 } from "../ModalContentV1"
import { ModalContentV2 } from "../ModalContentV2"

export const Modal = (props) => {

    const [loading , setLoading] = useState(true)
    const {modalVariant ,callBack, info,secCallBack,thrCallBack} = props.props ? props.props : false
    const handleClick = async (event) => {
        if (!loading) {
            if (callBack) {
                callBack(false)
                secCallBack(false)
                thrCallBack(false)
            } else if(event.target === event.currentTarget){
                event.currentTarget.classList.add('hidden')
            }  
        }
    }
    

    
    const contentHandle = (variant) => {



        switch (variant) {
            case "start":
                return <ModalContentV1 />
            case "mapitem":
                return <ModalContentV2 info={info} callBack={setLoading}  />
            default:
                break;
        }

    }

    // useEffect(()=>{
    //     if (Object.keys(props).length < 1) {
    //         document.querySelector(`.modal-wrapper[var=${id}]`).classList.add('hidden')
    //     }
    // },[])


    return (
        <>
            {modalVariant ? (
                <>
                    <div className={` modal-wrapper`} onClick={handleClick}>
                        {/* <ModalContentV1 /> */}
                        {modalVariant ? contentHandle(modalVariant) : null}
                    </div>
                </>
            ):null}
            
            
        </>
    )
}